import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Box, Button, FormControl, Grid2, TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useFormik } from "formik";
import ContactImage from "../../assets/images/266936504.jpeg";
import * as Yup from "yup";
import { constants } from "./contact.constants";

const servicesList = constants.services;

const Contact = () => {
  useEffect(() => {
    document.title = `${constants.title} | Aurora Rising`;
    document
      .querySelector("link[rel='canonical']")
      .setAttribute("href", window.location);
  });

  const statusRef = useRef();

  const setStatus = text => {
    if (statusRef.current) {
      statusRef.current.innerText = text;
    }
  };

  const [sent, setIsSent] = useState(false);
  const [disableSend, setDisableSend] = useState(true);
  const formValidation = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required")
  });

  const myForm = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      booking: "",
      service: "General Inquiry",
      message: ""
    },
    validationSchema: formValidation,
    onSubmit: values => {
      axios
        .post("/contact_submit.php", values)
        .then(response => {
          setStatus(response.data);
          setDisableSend(true);
          setStatus(
            "Thank you for your submission. We will get back to you as soon as possible."
          );
        })
        .catch(error => {
          setDisableSend(true);
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log("Error", error.message);
          }
          console.log(error.config);
          setStatus(
            "Failed to submit. Please contact us via email at info@aurora-rising.ca."
          );
        });
    }
  });

  const handleSend = () => {
    myForm.submitForm();
    setIsSent(true);
  };

  useEffect(() => {
    myForm.setFieldTouched("name", true);
    //eslint-disable-next-line
  }, []);

  useEffect(
    () => {
      if (myForm.isValid) {
        setDisableSend(false);
      } else {
        if (!myForm.isValid) {
          setDisableSend(true);
        }
      }
    },
    [myForm.isValid]
  );

  return (
    <Grid2 container spacing={2} columns={12}>
      <Grid2 item size={12}>
        <h1>Contact Us</h1>
      </Grid2>
      <Grid2 item size={{ xs: 12, lg: 6 }}>
        <Box
          component={"img"}
          sx={{ maxWidth: "100%" }}
          src={ContactImage}
          alt="Contact Us"
        />
        {constants.schedulingText.map((text, index) => {
          return (
            <Box key={index} component={text.tag}>
              {text.strong
                ? <strong>
                    {text.strong + " "}
                  </strong>
                : ""}
              {text.text}
            </Box>
          );
        })}
      </Grid2>

      <Grid2 item size={{ xs: 12, lg: 6 }}>
        <FormControl>
          <Box component={"form"}>
            <div>
              <Grid2 container spacing={3}>
                <Grid2 container size={12}>
                  <Grid2 size={{ xs: 12, lg: 6 }}>
                    <TextField
                      required
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      value={myForm.values.firstName}
                      onChange={myForm.handleChange}
                      fullWidth
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, lg: 6 }}>
                    <TextField
                      required
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      value={myForm.values.lastName}
                      onChange={myForm.handleChange}
                      fullWidth
                    />
                  </Grid2>
                </Grid2>
                <Grid2 container size={12}>
                  <Grid2 size={{ xs: 12, lg: 6 }}>
                    <TextField
                      id="email"
                      name="email"
                      label="Email"
                      value={myForm.values.email}
                      onChange={myForm.handleChange}
                      error={!!myForm.errors.email}
                      fullWidth
                      required
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, lg: 6 }}>
                    <TextField
                      required
                      id="phone"
                      name="phone"
                      label="Phone"
                      value={myForm.values.phone}
                      onChange={myForm.handleChange}
                      fullWidth
                    />
                  </Grid2>
                </Grid2>
                <Grid2 container size={12}>
                  <Grid2 size={{ xs: 12 }}>
                    <TextField
                      id="outlined-select-services-native"
                      select
                      label="Service"
                      name="service"
                      onChange={myForm.handleChange}
                      value={myForm.values.service}
                      fullWidth
                      slotProps={{
                        select: {
                          native: true
                        }
                      }}
                      helperText="Please select the service you would like to inquire about"
                    >
                      {servicesList.map(option =>
                        <option key={option} value={option}>
                          {option}
                        </option>
                      )}
                    </TextField>
                  </Grid2>
                </Grid2>
                <Grid2 container size={12}>
                  <Grid2 size={{ xs: 12 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        format="DD/MM/YYYY hh:mm a"
                        label="Ideal Booking Date/Time"
                        onChange={value =>
                          myForm.setFieldValue("booking", value)}
                        fullWidth
                      />
                    </LocalizationProvider>
                  </Grid2>
                </Grid2>
                <Grid2 container size={12}>
                  <Grid2 size={{ xs: 12 }}>
                    <TextField
                      id="outlined-message-flexible"
                      label="Message"
                      name="message"
                      value={myForm.values.message}
                      onChange={myForm.handleChange}
                      fullWidth
                      multiline
                      rows={9}
                    />
                  </Grid2>
                </Grid2>
                <Grid2 container size={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={disableSend}
                    onClick={handleSend}
                    sx={{ background: "#3d276f" }}
                  >
                    Submit
                  </Button>
                  {!myForm.isValid &&
                    <p>
                      Please fill out all required fields (marked with a *)
                      before submitting the form.
                    </p>}
                </Grid2>
              </Grid2>
            </div>
          </Box>
        </FormControl>
        <div>
          <p ref={statusRef} />
        </div>
      </Grid2>
    </Grid2>
  );
};

export default Contact;
